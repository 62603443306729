<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

    <dashboard-core-view />

    <!-- dashboard-core-settings v-model="expandOnHover" /-->
  </v-app>
</template>

<script>

import { EventBus } from '@/code/util/eventBus';
import { UserService } from '@/code/services/user/userService';
import localStorageUtil from '@/code/util/localStorageUtil';

  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('@/components/dashboard/core/AppBar.vue'),
      DashboardCoreDrawer: () => import('@/components/dashboard/core/Drawer.vue'),
      DashboardCoreSettings: () => import('@/components/dashboard/core/Settings.vue'),
      DashboardCoreView: () => import('@/components/dashboard/core/View.vue'),
    },
    data () {
      return {
        expandOnHover: false,
      }
    },
    mounted() {
      var userService = new UserService();
      userService.getCurrentUser();
      const token = localStorageUtil.getToken();
      if (token) {
        this.$store.commit('setToken', token)
      }
    }
  }
</script>
